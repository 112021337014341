export const AJ = ({className='icon'}) => {
    return (        
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" id="Capa_1" fill='currentColor' stroke='currentColor' height='1em' width='1em' viewBox="0 0 394.263 394.263" className={className}>
            <g>
                <g>
                    <path d="M364.015,187.592V26.466C364.015,11.839,352.146,0,337.551,0H44.858C30.261,0,18.393,11.839,18.393,26.466v292.66
			c0,14.598,11.869,26.468,26.465,26.468h46.737c4.784,4.264,9.814,8.311,15.027,12.113c32.999,23.922,71.732,36.556,112.122,36.556
			l0,0c10.119,0,20.363-0.798,30.422-2.393c33.674-5.337,63.85-21.593,86.605-46.276h1.78c14.598,0,26.464-11.872,26.464-26.468
			v-17.173c10.888-25.608,14.444-53.854,9.967-82.005C372.173,208.569,368.77,197.715,364.015,187.592z M57.554,276.746
			l12.022,0.642l-6.686,10.214C60.99,284.041,59.21,280.423,57.554,276.746z M114.995,346.728c-0.092-0.065-0.184-0.124-0.276-0.154
			c-4.202-3.097-8.219-6.316-12.115-9.69l15.242-5.367L114.995,346.728z M173.968,374.635l10.058-11.655l5.216,14.996
			C184.088,377.086,178.998,375.953,173.968,374.635z M253.643,377.056l5.889-16.529l10.243,12.085
			C264.5,374.389,259.162,375.891,253.643,377.056z M332.888,327.742l-10.825,11.379l-5.093-17.355l17.297,4.168
			C333.808,326.547,333.377,327.16,332.888,327.742z M337.795,321.15l-28.799-6.992l8.802,29.964
			c-12.421,11.407-26.803,20.361-42.506,26.464L257.505,349.7l-10.12,28.492c-0.123,0.029-0.244,0.029-0.369,0.062
			c-9.384,1.502-18.893,2.238-28.276,2.238l0,0c-7.88,0-15.642-0.645-23.369-1.657l-9.323-26.68l-17.941,20.82
			c-16.958-5.09-33.151-12.786-48.117-22.937l5.031-26.896l-27.109,9.535c-12.574-11.713-23.308-25.084-31.925-39.712l13.402-20.55
			l-24.349-1.349c-5.306-12.821-9.169-26.406-11.408-40.45C30.994,151.127,60.312,72.067,122.077,18.706l14.659-11.041h125.337
			l-37.994,11.041C137.138,43.914,80.311,130.796,94.847,222.491c5.274,33.182,23.184,62.313,50.356,82.066
			c21.681,15.732,47.136,24.041,73.664,24.041c6.653,0,13.37-0.521,19.994-1.591c38.458-6.075,71.364-43.337,71.824-81.331
			c0.243-16.9-5.889-32.141-17.695-44.1c-15.181-15.365-39.286-24.565-64.432-24.565c-11.164,0-21.896,1.748-32.017,5.244
			c-2.331,0.796-4.631,1.228-6.685,1.228c-5.766,0-7.208-3.038-7.882-5.981c-1.748-7.912,8.25-17.91,11.563-21.192
			c0.921-0.919,1.779-1.808,2.516-2.636c12.02-10.581,26.678-17.512,42.503-20.025c27.971-4.417,56.309,2.361,79.245,19.013
			c23,16.683,38.122,41.341,42.566,69.431C365.946,257.086,357.882,292.14,337.795,321.15z"/>
                    <path d="M233.157,139.047c-8.647,0-15.641,7.022-15.641,15.64c0,8.647,6.993,15.67,15.641,15.67
			c8.648,0,15.642-7.023,15.642-15.67C248.798,146.068,241.805,139.047,233.157,139.047z M233.157,164.838
			c-5.582,0-10.121-4.539-10.121-10.15c0-5.582,4.537-10.121,10.121-10.121c5.582,0,10.122,4.539,10.122,10.121
			C243.278,160.298,238.739,164.838,233.157,164.838z"/>
                </g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
        </svg>
    )
}